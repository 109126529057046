/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { Row, Col, Container, Card } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { default_theme as theme } from '../emotion/theme'
import { get, first } from 'lodash'
import { jsx, css } from '@emotion/react'
import { CardHeader } from './layout/CardHeader'
import { PhoneNumber } from './PhoneNumber'
import { Error } from './layout/Error'
import CardH2Text from './layout/CardH2Text'
import MainLayout from './MainLayout'
import WrappingBusyMask from './WrappingBusyMask'
import PaySubscriptionsNotification from './PaySubscriptionsNotification'
import CurrencyValue from './CurrencyValue'
import { BankTransferPaymentDetails } from './BankTransferPaymentDetails'
import { topupList } from '../actions/topup'
import { Separator } from './layout/Separator'
import { Trans, Translation } from 'react-i18next'
import { BlueButton } from './layout/BlueButton'
import CardInfoText from './layout/CardInfoText'
import { subscriptionList } from '../actions/customer_subscription'
import { customerSubscriptionManualReservationList } from '../actions/customer_subscription_manual_reservation'
import { customerList } from '../actions/customer'
import { molliePaymentList } from '../actions/mollie_payment'
import { globalSettingsList } from '../actions/settings'
import { InlineIcon } from './layout/InlineIcon'

const CARD_WIDTH = '4'

export const SubscriptionConfirmation = ({ ...props }) => {
  const { subscription_id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const customer = useSelector(() => customerList.getCustomer())
  const subscription = useSelector(() =>
    subscriptionList.getObject(subscription_id)
  )
  const reservation = useSelector(() =>
    first(customerSubscriptionManualReservationList.getVisibleObjects())
  )
  const mostRecentMolliePayment = get(subscription, "most_recent_mollie_payment")
  const isLoading = useSelector(
    () =>
      customerList.isLoading() ||
      subscriptionList.isLoading() ||
      molliePaymentList.isWaitingForObjectLoad()
  )
  const threshold_for_low_credit_balance =
    useSelector(
      () =>
        globalSettingsList.getSetting(
          'credit_balance_excluding_vat_euros_to_trigger_warning_email'
        ) === true
    ) || null

  useEffect(() => {
    dispatch(customerList.ensureCustomerLoaded())
    dispatch(globalSettingsList.fetchListIfNeeded())
  }, [])

  useEffect(() => {
    dispatch(subscriptionList.invalidateObject(subscription_id))
    dispatch(subscriptionList.ensureObjectLoaded(subscription_id))
    dispatch(
      customerSubscriptionManualReservationList.updateListFilter({
        subscription: subscription_id,
      })
    )
    dispatch(customerSubscriptionManualReservationList.fetchListIfNeeded())
  }, [subscription_id])

  const renderTitle = () => {
    const is_waiting_for_number = subscription.is_waiting_for_number

      return (
          <>

            { subscription.amount_owing_excluding_vat_euros > 0 &&
              <>
                { subscription.is_active &&
                  <Trans>Your subscription is not paid yet.</Trans>
                }
                { ! subscription.is_active &&
                  <Trans>Your subscription is not active yet.</Trans>
                }
              </>
              
            }

            { subscription.amount_owing_excluding_vat_euros == 0 &&
              <>
                
                {!is_waiting_for_number && subscription.is_active && (
                    <Trans>Your subscription is now active.</Trans>
                )}
                {!is_waiting_for_number && !subscription.is_active && (
                    <Trans>Your subscription is not active yet.</Trans>
                )}
                {is_waiting_for_number && (
                    <>
                      <Trans
                          i18nKey="subscription_confirmation_title__your_number_is_being_processed"
                          defaults="Your number is being processed"
                      />
                      {get(reservation, ['phone_number_prefix', 'prefix']) && (
                          <span>&nbsp;({reservation.phone_number_prefix.prefix})</span>
                      )}
                    </>
                )}
              </>
            }
          </>
      )
  }

  const onContinue = () => {
    history.push(getContinueUrl())
  }

  const onBuyMoreCredit = () => {
    topupList.startBuyMoreCredit({
      history,
      optionalReturnUrl: getContinueUrl(),
    })
  }

  //   const getContinueUrl = () => {
  // if ( subscription.product_can_receive_voice ) {
  //     return "/voice"
  // } else if ( subscription.product_can_receive_faxes ) {
  //     return "/send_a_fax"
  // } else {
  //     return "/"
  // }
  //   }

    const onCancelled = () => {
        history.push('/subscriptions')
    }
    
  const getContinueUrl = () => {
    if (
      !subscription.is_waiting_for_number &&
      subscription.is_active &&
      subscription.product_can_receive_voice
    ) {
      return `/voice/${subscription_id}/call_plan`
    } else {
      return '/'
    }
  }

  const renderVoiceCredit = () => {
    const has_free_minutes =
      subscription.remaining_monthly_free_voice_forwarding_minutes > 0
    const voicemail_is_free =
      !Boolean(subscription.voicemail_rate_per_minute_excluding_vat_euros) &&
      !Boolean(subscription.voicemail_start_rate_excluding_vat_euros)

    const zero_credit = customer.credits_excluding_vat_euros === 0
    const sufficient_credit =
      threshold_for_low_credit_balance === null
        ? customer.credits_excluding_vat_euros >
          threshold_for_low_credit_balance
        : customer.credits_excluding_vat_euros > 0
    const low_credit =
      threshold_for_low_credit_balance === null
        ? false
        : customer.credits_excluding_vat_euros > 0 &&
          customer.credits_excluding_vat_euros <=
            threshold_for_low_credit_balance
    const is_waiting_for_number = subscription.is_waiting_for_number

    const no_free_minutes__no_free_voicemail =
      !is_waiting_for_number && !has_free_minutes && !voicemail_is_free
    const no_free_minutes__yes_free_voicemail =
      !is_waiting_for_number && !has_free_minutes && voicemail_is_free
    const yes_free_minutes__no_free_voicemail =
      !is_waiting_for_number && has_free_minutes && !voicemail_is_free
    const yes_free_minutes__yes_free_voicemail =
      !is_waiting_for_number && has_free_minutes && voicemail_is_free

    if (is_waiting_for_number || yes_free_minutes__yes_free_voicemail) {
      return null
    }

    return (
      <Card css={mini_card_style}>
        <CardH2Text>
          <Trans>XOIP Credits</Trans>
        </CardH2Text>

        <Separator variant="h20" />

        <div>
          {sufficient_credit && (
            <Trans
              i18nKey="subscription_confirmation__your_current_xoip_credit_balance"
              values={{ credits: customer.credits_excluding_vat_euros }}
              components={[
                <CurrencyValue
                  extraCss={accent_style}
                  use_span={true}
                  value={customer.credits_excluding_vat_euros}
                />,
              ]}
            />
          )}
          {low_credit && (
            <Trans
              i18nKey="subscription_confirmation__your_current_xoip_credit_balance_low"
              values={{ credits: customer.credits_excluding_vat_euros }}
              components={[
                <CurrencyValue
                  extraCss={accent_style}
                  use_span={true}
                  value={customer.credits_excluding_vat_euros}
                />,
              ]}
            />
          )}
          {zero_credit && (
            <Trans i18nKey="subscription_confirmation__your_current_xoip_credit_balance_zero" />
          )}
          <Separator variant="h20" />
          {no_free_minutes__no_free_voicemail && (
            <Trans i18nKey="subscription_confirmation__no_free_minutes__no_free_voicemail" />
          )}
          {no_free_minutes__yes_free_voicemail && (
            <Trans i18nKey="subscription_confirmation__no_free_minutes__yes_free_voicemail" />
          )}
          {yes_free_minutes__no_free_voicemail && (
            <Trans i18nKey="subscription_confirmation__yes_free_minutes__no_free_voicemail" />
          )}
          &nbsp;
          {sufficient_credit && (
            <>
              <Trans i18nKey="subscription_confirmation__if_your_balance_runs_low" />
            </>
          )}
          {low_credit && (
            <Trans i18nKey="subscription_confirmation__if_your_balance_runs_low" />
          )}
          {zero_credit && (
            <Trans i18nKey="subscription_confirmation__you_must_first_purchase" />
          )}
          <Separator variant="h20" />
          <BlueButton
            onClick={onBuyMoreCredit}
            auto_disable={false}
            extra_css={button_style}
          >
            <Trans>Purchase XOIP Credits</Trans>
          </BlueButton>
        </div>
      </Card>
    )
  }

  const renderFaxReceivingDetails = () => {
    return (
      <Card css={mini_card_style}>
        <CardH2Text>
          <Trans>Receiving faxes</Trans>
        </CardH2Text>

        <Separator variant="h20" />

        <div>
          <Trans
            i18nKey="subscription_confirmation__people_can_fax_you_on"
            values={{ number: subscription.phone_number_number }}
            components={[
              <span css={accent_style}>
                <PhoneNumber
                  phone_number={subscription.phone_number_number}
                  enable_inline_spacing={true}
                />
              </span>,
            ]}
            defaults="People can now fax you on <0>{{number}}</0>"
          />
        </div>

        <Separator variant="h20" />

        <div>
          <Trans
            i18nKey="subscription_confirmation__faxes_are_sent_to"
            values={{ email: customer.preferred_fax_confirmation_email }}
            components={[
              <span css={accent_style}>
                {customer.preferred_fax_confirmation_email}
              </span>,
            ]}
            defaults="Faxes are sent to <0>{{email}}</0>"
          />
        </div>

        <div>
          <Trans
            i18nKey="subscription_confirmation__you_can_change_fax_email"
            values={{ fax: 'Fax' }}
            components={[<span css={accent_style}>Fax</span>]}
            defaults="You can change it to another email address in the fax settings. Click on <0>Fax</0> in the menu bar."
          />
        </div>
      </Card>
    )
  }

  const renderFaxSendingDetails = () => {
    return (
      <Card css={mini_card_style}>
        <>
          <CardH2Text>
            <Trans>Sending faxes</Trans>
          </CardH2Text>

          <Separator variant="h20" />

          <div>
            <Trans
              i18nKey="subscription_confirmation__to_send_faxes"
              values={{ fax: 'Fax' }}
              components={[<span css={accent_style}>Fax</span>]}
              defaults="To send faxes, click on <0>Fax</0> in the menu bar (or click 'Continue' below). It is as simple as uploading a file and entering the recipient's fax number."
            />
          </div>

          <Separator variant="h20" />

          <div>
            <Trans
              i18nKey="subscription_confirmation__you_can_also_send_faxes"
              values={{ faxpattern: '<i>faxnumber</i>@fax.xoip.com' }}
              components={[
                <span css={accent_style}>
                  <i>faxnumber</i>@fax.xoip.com
                </span>,
              ]}
              defaults="You can also send faxes from your email. Attach a pdf to an email, addressed to <0>{{faxpattern}}</0>."
            />
          </div>
        </>
      </Card>
    )
  }

    const renderMolliePaymentFailed = () => {
        return (
            <>
              <PaySubscriptionsNotification initialSubscriptionId={subscription.id} onCancelled={onCancelled} />
            </>
        )
    }
    
  const renderVoiceNumberDetails = () => {
    const is_waiting_for_number = subscription.is_waiting_for_number
    const number_requires_regular_use =
        subscription.product_minimum_paid_minutes_before_cancellation > 0
      
    return (
        <Card css={mini_card_style}>

        {is_waiting_for_number && (
          <>
            <Trans
              i18nKey="subscription_confirmation__waiting_for_number"
              defaults="We will send you an email as soon as your number is activated."
            />
          </>
        )}

        {!is_waiting_for_number && (
          <>
            <CardH2Text>
              <Trans>Details</Trans>
            </CardH2Text>

            <Separator variant="h20" />

            <div>
              <Trans
                i18nKey="subscription_confirmation__your_number_is"
                values={{ number: subscription.phone_number_number }}
                components={[
                  <span css={accent_style}>
                    <PhoneNumber
                      phone_number={subscription.phone_number_number}
                      enable_inline_spacing={true}
                    />
                  </span>,
                ]}
                defaults="Your number is <0>{{number}}</0>"
              />
            </div>

            <div>
              <Trans
                i18nKey="subscription_confirmation__your_subscription_is"
                values={{ product_name: subscription.product_name }}
                components={[
                  <span css={accent_style}>{subscription.product_name}</span>,
                ]}
                defaults="Your subscription is <0>{{product_name}}</0>"
              />
            </div>

            <Separator variant="h20" />

            <div>
              <Trans
                i18nKey="subscription_confirmation__voicemails_will_be_delivered_to"
                values={{
                  email: subscription.default_voicemail_forwarding_email,
                }}
                components={[
                  <span css={accent_style}>
                    {subscription.default_voicemail_forwarding_email}
                  </span>,
                ]}
                defaults="Voicemails will be delivered to <0>{{email}}</0>"
              />
            </div>

            { !is_waiting_for_number && subscription.is_active &&
              <div>
                <Separator variant="h20" />
                <Trans
                    i18nKey="subscription_confirmation__you_can_configure_call_plan"
                    values={{ call_plan_link_name: 'Call Plan' }}
                    components={[
                        <a
                            css={link_accent_style}
                            href={`/voice/${subscription.id}/call_plan`}
                        >
                                Call Plan
                        </a>,
                    ]}
                    defaults="You can configure this and all call forwarding settings in the <0>{{call_plan_link_name}}</a>."
                />
              </div>
            }

            {number_requires_regular_use && (
              <>
                <Separator variant="h10" />
                <CardInfoText>
                  <Trans
                    i18nKey="subscription_confirmation__the_number_should_be_called_regularly"
                    defaults="Please note: The number should be called regularly for it to stay active."
                  />
                </CardInfoText>
              </>
            )}

            { subscription.amount_owing_excluding_vat_euros > 0 && subscription.preferred_payment_mechanism == 'offline' &&
              <>
                <Separator variant="h40" />
                <Trans>Please use the details below to make your payment</Trans>:
                <Separator variant="h10" />
                <Col>
                  <Trans>Amount due</Trans>: <span css={bold_style}><CurrencyValue value={subscription.amount_owing_including_vat_euros} use_span={true} /></span>
                  { customer && customer.vat_percentage > 0 &&
                    <>
                    &nbsp;(inc. {customer.vat_percentage}% <Trans>VAT</Trans>)
                    </>
                  }
                </Col>
                <Separator variant="h10" />
                <BankTransferPaymentDetails customer={customer} />
              </>
            }
            
            <Separator variant="h20" />
          </>
        )}
      </Card>
    )
  }

  return (
    <MainLayout
      active_key="subscription_confirmation"
      enable_breadlinks={false}
      disable_action_notifications={['pay_subscriptions']}
    >
      <Container fluid>
        <Row>
          <Col>
            <WrappingBusyMask is_loading={isLoading}>
              <CardHeader title={renderTitle()} />

              <Separator variant="h20" />

              {mostRecentMolliePayment && !get(mostRecentMolliePayment, 'is_paid') &&
               <Row>
                  <Col md={8} css={mollie_payment_failed_container_style}>
                    {renderMolliePaymentFailed()}
                  </Col>
               </Row>
              }
      
              <Row>
                <Col md={CARD_WIDTH}>

                  {subscription.product_can_receive_voice &&
                    renderVoiceNumberDetails()}
                  {subscription.product_can_receive_faxes &&
                    renderFaxReceivingDetails()}
                  {!subscription.product_can_receive_faxes &&
                    subscription.product_can_send_faxes &&
                    renderFaxSendingDetails()}
                </Col>
                <Col md={CARD_WIDTH}>
                  {subscription.product_can_receive_voice &&
                    renderVoiceCredit()}
                  {subscription.product_can_receive_faxes &&
                    subscription.product_can_send_faxes &&
                    renderFaxSendingDetails()}
                </Col>
              </Row>
            </WrappingBusyMask>
          </Col>
        </Row>
        <Row>
          <Col>
            <BlueButton onClick={onContinue}>
              <div css={continue_style}>
                {!subscription.is_waiting_for_number &&
                subscription.is_active &&
                subscription.product_can_receive_voice ? (
                  <Trans i18nKey="subscription_confirmation__open_call_plan">
                    Open call plan
                  </Trans>
                ) : (
                  <Trans i18nKey="subscription_confirmation__continue">
                    Continue
                  </Trans>
                )}
                &nbsp;
                <InlineIcon variant="white" icon_name="chevron-right" />
              </div>
            </BlueButton>
          </Col>
        </Row>
      </Container>
    </MainLayout>
  )
}

const mini_card_style = css`
  background-color: #fff;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 40px;
  margin-right: 30px;
  position: relative;
  min-height: 300px;
  margin-bottom: 30px;
`

const continue_style = css`
  display: flex;
  align-items: center;
`

const accent_style = css`
  font-weight: bold;
`

const button_style = css`
  width: max-content;
`

const link_accent_style = css`
  font-weight: bold;
  &:hover: {
    text-decoration: underline;
  }
`

const mollie_payment_failed_container_style = css`
  margin-left: -30px;
`

const bold_style = css`
font-weight: 500;
`
