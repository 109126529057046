/** @jsxImportSource @emotion/react */
import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { get } from 'lodash'
import { AdminQuickLink } from './AdminQuickLink'
import { Separator } from '../../components/layout/Separator'
import CurrencyValue from '../../components/CurrencyValue'
import Timestamp from '../../components/Timestamp'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import { adminIncomingMolliePaymentList } from '../actions/admin_mollie_payments'

export const AdminMolliePaymentInfo = ({mollie_payment_id}) => {

    const dispatch = useDispatch()
    dispatch(adminIncomingMolliePaymentList.ensureObjectLoaded(mollie_payment_id))
    const mollie_payment = useSelector(() => adminIncomingMolliePaymentList.getObject(mollie_payment_id))

    return (
        <div>
          <Row>
            <Col>Internal ID</Col>
            <Col>{mollie_payment.short_ref}</Col>
          </Row>
          <Row>
            <Col>Mollie Ref</Col>
            <Col>{mollie_payment.mollie_ref}</Col>
          </Row>
          <Row>
            <Col>XOIP Transaction</Col>
            <Col>
        { mollie_payment.transaction &&

              <AdminQuickLink name='transaction' label={mollie_payment.transaction_short_ref} value={mollie_payment.transaction} />
              }
              { ! mollie_payment.transaction && 
                <i>No XOIP transaction created because of payment failure</i>
              }
            </Col>
          </Row>
          <Row>
            <Col>Created at</Col>
            <Col><Timestamp value={mollie_payment.created_at} format='datetime' /></Col>
          </Row>
          <Row>
            <Col>Paid at</Col>
            <Col><Timestamp value={mollie_payment.paid_at} format='datetime' /></Col>
          </Row>
          <Row>
            <Col>Payment reason</Col>
            <Col>{mollie_payment.payment_reason}</Col>
          </Row>
          <Row>
            <Col>Amount (ex Vat)</Col>
            <Col><CurrencyValue value={mollie_payment.amount_excluding_vat_euros}/></Col>
          </Row>
          <Row>
            <Col>Mollie Status</Col>
            <Col>{mollie_payment.mollie_status}</Col>
          </Row>
          <Row>
            <Col>Error</Col>
            <Col>{mollie_payment.error_message}</Col>
          </Row>
          <Row>
            <Col>Customer</Col>
            <Col><AdminQuickLink name='customer' label={mollie_payment.customer_name} value={mollie_payment.customer} /></Col>
          </Row>
        </div>
    )
}
