/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { map } from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import { globalSettingsList } from '../actions/settings'
import { Trans, Translation } from 'react-i18next'
import { Row, Col } from 'react-bootstrap'

export const BankTransferPaymentDetails = ({customer}) => {

    const dispatch = useDispatch()
    dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
    const subscription_bank_transfer_details = useSelector(() => globalSettingsList.getSetting('subscription_bank_transfer_details'))

    return (

        <div>
          {map(subscription_bank_transfer_details, (line) => {
              return (
                  <Col>
                    <Trans>{line}</Trans>
                  </Col>
              )
          })}
          <Col>
            <b>
              <Trans>Reference number</Trans>:
        &nbsp;&nbsp;
        {customer.payment_ref}
            </b>
          </Col>
        </div>
    )
    
}
