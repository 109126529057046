/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'

export const CustomerNotificationCard = ({is_active, override_style, children, ...props}) => {

    return (
        <div css={[card_style,
                   is_active ? active_card_style : inactive_card_style,
                   override_style || null
        ]}>
          {children}
        </div>
    )
    
}

export const CustomerNotificationCardHeader = ({children, ...props}) => {
    return (
        <div css={heading_style}>
          {children}
        </div>
    )
}


const card_style = css(theme.mq({
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.2)",
    borderRadius: "4px",
    padding: ["30px", "10px"],
    marginLeft: ["30px", "0px"],
    marginRight: ["30px", "0px"],
}))

const inactive_card_style = css`
background-color: rgba(236, 42, 31, 0.1);
border: solid 1px #ec2a1f;
`

const active_card_style = css`
border: solid 1px #ffcc00;
background-color: rgba(255, 204, 0, 0.1);
`

const low_priority_card_style = css`
border: solid 1px #ffcc00;
background-color: rgba(255, 204, 0, 0.1);
`

const heading_style = css`
font-weight: 500;
font-size: 18px;
margin-bottom: 4px;
`
