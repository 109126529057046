/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { head, get, map, keys, split, size } from 'lodash'
import { adminTransactionList } from '../actions/admin_transaction'
import { CardHeader } from '../../components/layout/CardHeader'
import { AdminQuickLink } from './AdminQuickLink'
import { LanguageFlag } from '../../components/LanguageFlag'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { Separator } from '../../components/layout/Separator'
import CurrencyValue from '../../components/CurrencyValue'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from '../../components/Loading'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikInputField } from '../../components/form/InputField'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import Button from 'react-bootstrap/Button'
import CardInfoText from '../../components/layout/CardInfoText'
import { adminTransactionTypes } from '../actions/admin_dropdown_options'
import { AdminTransactionInfo } from './AdminTransactionInfo'

class AdminTransaction extends Component {

    componentDidMount() {
        const { dispatch, transaction_id } = this.props
        dispatch(adminTransactionList.ensureObjectLoaded(transaction_id))
        dispatch(adminTransactionTypes.fetchListIfNeeded())        
    }

    componentDidUpdate(prev_props) {
        const { dispatch, transaction_id } = this.props
        dispatch(adminTransactionList.ensureObjectLoaded(transaction_id))
    }

    renderTransaction() {
        const { transaction } = this.props
        return <AdminTransactionInfo transaction_id={transaction.id} />
    }

    render() {
        const { initial_values, is_loading, is_busy, transaction, transaction_id } = this.props
        const that = this
        const title = `${get(transaction, ["description"])}`

        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           
                                           {name: 'transactions',
                                            label: 'Transactions',
                                            url: '/admin/transactions'},
                                           
                                           {name: 'transaction',
                                            label: (
                                                <div css={breadcrumb_item}>
                                                  {get(transaction, ["description"])}
                                                </div>
                                            ),
                                            url: `/admin/transaction/${transaction_id}`
                                           }
            ]}>
              <Container fluid>
                { is_busy && <BusyMask /> }
                { is_loading && <Loading /> }

                <CardHeader title={title} />
                <Separator variant="h30" />
                
                { ! is_loading && 
                  <Row>
                    <Col>
                      <Card>
                        { that.renderTransaction() }
                      </Card>
                    </Col>
                  </Row>
                }
              </Container>
            </AdminMainLayout>
        )
    }
    
}

function mapStateToProps(state, props) {
    const transaction_id = get(props, ["match", "params", "transaction_id"], null)
    const transaction = adminTransactionList.getObject(transaction_id)
    return {
        transaction_id,
        transaction,
        is_loading: adminTransactionList.isLoading(),
        is_busy: adminTransactionList.getIsSavingObject(),
        initial_values: transaction,
    }
}

export default connect(mapStateToProps)(AdminTransaction)

const breadcrumb_item = css`
display: flex;
align-items: center;
`
