import { ItemList } from '../../orm'
import { download } from '../../actions/lib'

class AdminOfflineSubscriptionTransactionList extends ItemList {
    getEntityKey() {
        return "admin/offline_subscription_transaction"
    }
    
}

export const adminOfflineSubscriptionTransactionList = new AdminOfflineSubscriptionTransactionList("admin_offline_subscriptiontransaction__default")
