/** @jsxImportSource @emotion/react */
import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { head, get, map, keys, split, size } from 'lodash'
import { CardHeader } from '../../components/layout/CardHeader'
import { AdminQuickLink } from './AdminQuickLink'
import { LanguageFlag } from '../../components/LanguageFlag'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { Separator } from '../../components/layout/Separator'
import CurrencyValue from '../../components/CurrencyValue'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from '../../components/Loading'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikInputField } from '../../components/form/InputField'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import Button from 'react-bootstrap/Button'
import CardInfoText from '../../components/layout/CardInfoText'
import { AdminMolliePaymentInfo } from './AdminMolliePaymentInfo'
import { adminIncomingMolliePaymentList } from '../actions/admin_mollie_payments'

export const AdminMolliePayment = ({}) => {

    const dispatch = useDispatch()
    const { mollie_payment_id } = useParams()
    dispatch(adminIncomingMolliePaymentList.ensureObjectLoaded(mollie_payment_id))
    const mollie_payment = useSelector(() => adminIncomingMolliePaymentList.getObject(mollie_payment_id))
    const is_loading = useSelector(() => adminIncomingMolliePaymentList.isLoading())
    const title = `Mollie Payment ${get(mollie_payment, ["mollie_ref"])}`

    const renderMolliePayment = () => {
        return <AdminMolliePaymentInfo mollie_payment_id={mollie_payment_id} />
    }

    return (
        <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                       
                                       {name: 'mollie_payments',
                                        label: 'Mollie payments',
                                        url: '/admin/mollie_payments'},
                                       
                                       {name: 'mollie payment',
                                        label: (
                                            <div css={breadcrumb_item}>
                                                     {get(mollie_payment, ["short_ref"])}
                                            </div>
                                        ),
                                        url: `/admin/mollie_payment/${mollie_payment_id}`
                                       }
        ]}>
          <Container fluid>
            { is_loading && <Loading /> }

            <CardHeader title={title} />
            <Separator variant="h30" />
            
            { ! is_loading && 
              <Row>
                <Col>
                  <Card>
                    { renderMolliePayment() }
                  </Card>
                </Col>
              </Row>
            }
          </Container>
        </AdminMainLayout>
    )
    
}


const breadcrumb_item = css`
    display: flex;
    align-items: center;
`
