/** @jsxImportSource @emotion/react */
import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { jsx, css } from '@emotion/react'
import { get } from 'lodash'
import { adminTransactionList } from '../actions/admin_transaction'
import { AdminQuickLink } from './AdminQuickLink'
import { Separator } from '../../components/layout/Separator'
import CurrencyValue from '../../components/CurrencyValue'
import Timestamp from '../../components/Timestamp'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import { adminTransactionTypes } from '../actions/admin_dropdown_options'

export const AdminTransactionInfo = ({transaction_id}) => {

    const dispatch = useDispatch()
    dispatch(adminTransactionList.ensureObjectLoaded(transaction_id))
    dispatch(adminTransactionTypes.fetchListIfNeeded())
    const transaction = useSelector(() => adminTransactionList.getObject(transaction_id))

    return (
        <div>
          <Row>
            <Col>Xoip Id</Col>
            <Col>{transaction.short_ref}</Col>
          </Row>
          <Row>
            <Col>Mollie or other external reference</Col>
            <Col>{transaction.external_ref}</Col>
          </Row>
          <Row>
            <Col>Executed at</Col>
            <Col><Timestamp value={transaction.executed_at} format='datetime' /></Col>
          </Row>
          <Row>
            <Col>Type</Col>
            <Col>{get(adminTransactionTypes.getObject(transaction.transaction_type), "name")}</Col>
          </Row>
          <Row>
            <Col>Description</Col>
            <Col>{transaction.description}</Col>
          </Row>
          <Row>
            <Col>Payment system</Col>
            <Col>{transaction.payment_system}</Col>
          </Row>
          <Row>
            <Col>Amount exVAT</Col>
            <Col><CurrencyValue value={transaction.amount_excluding_vat_euros} /></Col>
          </Row>
          <Row>
            <Col>VAT percentage</Col>
            <Col>{transaction.vat_percentage}%</Col>
          </Row>
          <Row>
            <Col>VAT</Col>
            <Col><CurrencyValue value={transaction.vat_euros} /></Col>
          </Row>
          <Row>
            <Col>Euros including VAT</Col>
            <Col><CurrencyValue value={transaction.amount_including_vat_euros} /></Col>
          </Row>
          <Row>
            <Col>From</Col>
            <Col>{transaction.debtor_display_name}</Col>
          </Row>
          <Row>
            <Col>To</Col>
            <Col>{transaction.creditor_display_name}</Col>
          </Row>
          <Row>
            <Col>Customer</Col>
            <Col><AdminQuickLink name='customer' label={transaction.linked_customer_display_name} value={transaction.linked_customer_ref} /></Col>
          </Row>
          
        </div>
    )
}
