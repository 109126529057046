/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { get, map } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { jsx, css } from '@emotion/react'
import { Trans, Translation } from 'react-i18next'
import { Timestamp } from './Timestamp'
import { molliePaymentList } from '../actions/mollie_payment'
import { customerList } from '../actions/customer'
import LoadingOverlay from './layout/loading_overlay/LoadingOverlay'
import { Modal } from 'react-bootstrap'
import { BankTransferPaymentDetails } from './BankTransferPaymentDetails'
import { customerSubscriptionPayableList } from '../actions/customer_subscription'
import ToolTip from './ToolTip'
import WrappingBusyMask from './WrappingBusyMask'
import { Button } from 'react-bootstrap'
import { BlueButton } from './layout/BlueButton'
import { GrayButton } from './layout/GrayButton'
import { RedButton } from './layout/RedButton'
import { handleSubmitResult } from '../actions/form'
import { Separator } from './layout/Separator'
import CurrencyValue from './CurrencyValue'
import { ButtonBar } from './layout/ButtonBar'

export const PaySubscriptionMethodChoicePopup = ({newSubscriptionInfo, subscription, agreedTermsAndConditions, onClose}) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const [showPayByCredits, setShowPayByCredits] = useState(false)
    const [payByMollieLoading, setPayByMollieLoading] = useState(false)
    const [payWithCreditsResult, setPayWithCreditsResult] = useState(null)
    const [payByBankTransfer, setPayByBankTransfer] = useState(false)
    const [navigatingToMollie, setNavigatingToMollie] = useState(false)
    const [subscriptionToPay, setSubscriptionToPay] = useState(subscription)
    
    dispatch(customerList.ensureCustomerLoaded())
    const customer = useSelector(() => customerList.getCustomer())

    const [inSaveNewSubscription, setInSaveNewSubscription] = useState(false)
    const isReady = useSelector(() => customerSubscriptionPayableList.isReady() && customerList.isReady())
    const isLoading = useSelector(() => customerSubscriptionPayableList.isLoading() || customerList.isLoading())
    const isSaving = useSelector(() => molliePaymentList.getIsSavingObject() || customerSubscriptionPayableList.getIsSavingObject() || inSaveNewSubscription)

    useEffect(() => {
        if ( amountOwingExcludingVatEuros() == 0 && newSubscriptionInfo ) {
            setInSaveNewSubscription(true)
            newSubscriptionInfo.onSaveNewSubscription({onDoneCallback: ({subscription, returnUrl}) => {
                dispatch(customerList.invalidateCustomer())
                dispatch(customerList.ensureCustomerLoaded())
                history.push(returnUrl)
                setInSaveNewSubscription(false)
            }})
        }
    }, [])
    
    const amountOwingExcludingVatEuros = () => {
        return get(subscriptionToPay, "amount_owing_excluding_vat_euros",
                   get(newSubscriptionInfo, ["paymentCostCalculation", 'amount_owing_excluding_vat_euros'], 0))
    }

    const amountOwingIncludingVatEuros = () => {
        return get(subscriptionToPay, "amount_owing_including_vat_euros",
                   get(newSubscriptionInfo, ["paymentCostCalculation", 'total_cost_euros_inc_vat'], 0))
    }

    const canPayWithCredits = () => {
        return get(subscriptionToPay, "can_pay_with_credits",
                   get(newSubscriptionInfo, ["paymentCostCalculation", 'can_pay_with_credits'], false))
    }
    
    const cannotPayWithCreditsReason = () => {
        return get(subscriptionToPay, "cannot_pay_with_credits_reason",
                   get(newSubscriptionInfo, ["paymentCostCalculation", 'cannot_pay_with_credits_reason'], null))
    }

    const canPayByBankTransfer = () => {
        return get(subscriptionToPay, "can_pay_by_bank_transfer",
                   get(newSubscriptionInfo, ["paymentCostCalculation", 'can_pay_by_bank_transfer'], false))
    }

    const cannotPayByBankTransferReason = () => {
        return get(subscriptionToPay, "cannot_pay_by_bank_transfer_reason",
                   get(newSubscriptionInfo, ["paymentCostCalculation", 'cannot_pay_by_bank_transfer_reason'], null))
    }

    const payBefore = () => {
        return get(subscriptionToPay, "expires_at")
    }
    
    const onPayByMollie = () => {
        const on_ok = function(mollie_record) {
            if ( mollie_record.checkout_url ) {
                setNavigatingToMollie(true)
                molliePaymentList.rememberPendingMolliePaymentId(mollie_record.id)
                window.location = mollie_record.checkout_url
            }

            if ( onClose ) {
                onClose({method: 'mollie'})
            }
        }

        const processMolliePayment = ({subscription, returnUrl}) => {
            setPayByMollieLoading(true)
            molliePaymentList.rememberAsReturnUrl(returnUrl)
            dispatch(molliePaymentList.startPaySubscription({subscription, agreedTermsAndConditions})).then((res) => handleSubmitResult({res, on_ok}))
        }

        if ( newSubscriptionInfo ) {
            setInSaveNewSubscription(true)
            newSubscriptionInfo.onSaveNewSubscription({onDoneCallback: ({subscription, returnUrl}) => {
                setSubscriptionToPay(subscription)
                setInSaveNewSubscription(false)
                processMolliePayment({subscription, returnUrl})
            }})
        } else {
            processMolliePayment({subscription, returnUrl: null})
        }
        
    }

    const onStartPayByCredits = () => {
        setShowPayByCredits(true)
    }

    const onCancelConfirmPayByCredits = () => {
        setShowPayByCredits(false)
    }

    const onMakePaymentWithCredits = () => {

        const on_ok = function({subscription, returnUrl}) {
            dispatch(customerList.invalidateCustomer())
            dispatch(customerList.ensureCustomerLoaded())
            dispatch(customerSubscriptionPayableList.invalidateObject(subscription.id))
            dispatch(customerSubscriptionPayableList.invalidateList())
            dispatch(customerSubscriptionPayableList.fetchListIfNeeded())

            if ( returnUrl ) {
                history.push(returnUrl)
            } else {
                setPayWithCreditsResult({status: 'success'})
            }
            if ( onClose ) {
                onClose({method: 'credits'})
            }
        }

        const processCreditsPayment = ({subscription, returnUrl}) => {
            dispatch(customerSubscriptionPayableList.payWithCredits({subscription_id: subscription.id}))
                .then((res) => handleSubmitResult({res, on_ok: () => on_ok({subscription, returnUrl})}))
        }
        
        if ( newSubscriptionInfo ) {
            setInSaveNewSubscription(true)
            newSubscriptionInfo.onSaveNewSubscription({onDoneCallback: ({subscription, returnUrl}) => {
                setSubscriptionToPay(subscription)
                setInSaveNewSubscription(false)
                processCreditsPayment({subscription, returnUrl})
            }})
        } else {
            processCreditsPayment({subscription,
                                   returnUrl: null})
        }
        
    }
    
    const onClosePayWithCreditsResultsModal = () => {
        history.goBack()
    }

    const onConfirmPayByBankTransfer = () => {
        const on_ok = function({subscription, returnUrl}) {
            dispatch(customerSubscriptionPayableList.invalidateObject(subscription.id))
            dispatch(customerList.invalidateCustomer())
            dispatch(customerList.ensureCustomerLoaded())
            if ( returnUrl ) {
                history.push(returnUrl)
            }
            if ( onClose ) {
                onClose({method: 'offline'})
            }
        }

        const processOfflinePayment = ({subscription, returnUrl}) => {
            dispatch(customerSubscriptionPayableList.payOffline({subscription_id: subscription.id}))
                .then((res) => handleSubmitResult({res, on_ok: () => on_ok({subscription, returnUrl})}))
        }
        
        if ( newSubscriptionInfo ) {
            setInSaveNewSubscription(true)
            newSubscriptionInfo.onSaveNewSubscription({onDoneCallback: ({subscription, returnUrl}) => {
                setSubscriptionToPay(subscription)
                setInSaveNewSubscription(false)
                processOfflinePayment({subscription, returnUrl})
            }})
        } else {
            processOfflinePayment({subscription,
                                   returnUrl: null})
        }
    }

    const renderPayByCredits = () => {
        return (
            <>
              <Modal.Body>
                <div>
                  { ! isSaving &&
                    <div>
                      <Trans
                          i18nKey="paysubscriptions__credits_confirmation"
                          components={[<CurrencyValue use_span={true} value={amountOwingExcludingVatEuros()} />,
                                       <CurrencyValue use_span={true} value={get(customer, "credits_excluding_vat_euros")} />]}
                          defaults="<0></0> will be deducted from your XOIP credit balance of <1></1> (VAT was already applied to your credit purchases)."
                      />
                      <Separator variant="h10" />
                      <div>
                        <Trans>Do you want to proceed?</Trans>
                      </div>
                    </div>
                  }
                  { isSaving && <br/> }
                </div>
              </Modal.Body>
              
              <Modal.Footer>
                <ButtonBar>
                  <Button variant="danger" onClick={onCancelConfirmPayByCredits}>
                    <Trans>Back</Trans>
                  </Button>
                  <Separator variant="w5" />
                  <Button variant="success" onClick={onMakePaymentWithCredits}>
                    <Trans>Confirm</Trans>
                  </Button>
                </ButtonBar>
              </Modal.Footer>
            </>
        )
    }

    const renderPaySubscriptionWithCreditsResult = () => {
        return (
            <Modal.Body>
              <div>
                <Trans>Your payment was successful, and your subscription has been paid</Trans>
              </div>
              <Separator variant="h10" />
              <div>
                <BlueButton onClick={onClosePayWithCreditsResultsModal}>Close</BlueButton>
              </div>
            </Modal.Body>
        )
        
    }

    const renderPayByBankTransfer = () => {
        return (
            <>
              <Modal.Body>
                <div>

                  { payBefore() &&
                  <Trans
                      i18nKey="paysubscriptionsmethodchoicepopup__existing_sub_pay_before"
                      components={[<Timestamp value={payBefore()} format="date_longmonth" use_span={true} />]}
                      defaults="Pay before <0></0> using the information below. Please make sure to use the correct payment reference."
                  />
                  }
                  { ! payBefore() &&
                    <Trans
                        i18nKey="paysubscriptionsmethodchoicepopup__new_sub_pay_before"
                        defaults="Pay using the information below. Please make sure to use the correct payment reference."
                    />
                  }
                  
                  <br/><br/>
                  <Trans>Once your payment is received, your subscription will be updated and the invoice will be made available.</Trans>
                  <br/>
                </div>
                <Separator variant="h20" />

                <Col>
                  <Trans>Total (inc VAT)</Trans>:
                  &nbsp;&nbsp;
                  <CurrencyValue use_span={true} value={amountOwingIncludingVatEuros()} />
                </Col>
                <Separator variant="h10" />
                <BankTransferPaymentDetails customer={customer} />
                
              </Modal.Body>

              <Modal.Footer>
                <ButtonBar>
                  <Button variant="danger" onClick={() => setPayByBankTransfer(false)}>
                    <Trans>Back</Trans>
                  </Button>
                  <Separator variant="w5" />
                  <Button variant="success" onClick={onConfirmPayByBankTransfer}>
                    <Trans>Confirm</Trans>
                  </Button>
                </ButtonBar>
              </Modal.Footer>
            </>
        )
    }
    
    const renderChoosePaymentMethod = () => {
        return (
            <Modal.Body>
              <p>
                <Trans>Choose a payment method</Trans>
              </p>

              <p>
                <BlueButton onClick={onPayByMollie}>
                  <Trans>Online payment</Trans>
                </BlueButton>
              </p>

              { canPayWithCredits() &&
                <p>
                  <BlueButton onClick={onStartPayByCredits}>
                    <Trans>Pay with XOIP Credits</Trans>
                  </BlueButton>
                </p>
              }
              { !canPayWithCredits() &&
                <p css={button_with_tooltip}>
                  <GrayButton disabled={true} onClick={{}}>
                    <Trans>Pay with XOIP Credits</Trans>
                  </GrayButton>
                  <ToolTip>
                    <Trans i18nKey={`paysubscriptions__cannot_pay_with_credits__${cannotPayWithCreditsReason()}`} />
                  </ToolTip>
                </p>
              }

              { canPayByBankTransfer() &&
                <p>
                  <BlueButton onClick={() => setPayByBankTransfer(true)}>
                    <Trans>Pay by bank transfer</Trans>
                  </BlueButton>
                </p>
              }
              { !canPayByBankTransfer() &&
                <p css={button_with_tooltip}>
                  <GrayButton disabled={true} onClick={{}}>
                    <Trans>Pay by bank transfer</Trans>
                  </GrayButton>
                  <ToolTip>
                    <Trans i18nKey={`paysubscriptions__cannot_pay_by_bank_transfer__${cannotPayByBankTransferReason()}`} />
                  </ToolTip>
                </p>
              }
              
              <p>
                <RedButton onClick={onClose}>
                  <Trans>Cancel</Trans>
                </RedButton>
              </p>
            </Modal.Body>
        )
    }

    const renderPayByMollieLoading = () => {
        return (
            <>
              <Separator variant="h40" />
            </>
        )
    }

    const renderSaveNewSubscription = () => {
        return (
            <p>
              <Trans>Creating subscription</Trans>...
            </p>
        )
    }
    
    return (
        <Modal show={true}
               size="lg"
               onHide={onClose}
        >

          <Modal.Header closeButton>
            <Modal.Title>
              <Trans>Pay subscription</Trans>
            </Modal.Title>
          </Modal.Header>

          <WrappingBusyMask isLoading={isSaving || isLoading || !isReady || navigatingToMollie}>

            <LoadingOverlay
                active={isSaving}
                spinner
                text={<Trans>Please wait a moment...</Trans>}
            >
              <>
                { inSaveNewSubscription && renderSaveNewSubscription() }
                
                { ! inSaveNewSubscription &&
                  <>

                    { !showPayByCredits && !payByMollieLoading && !payByBankTransfer && renderChoosePaymentMethod() }
                    
                    { payByMollieLoading &&
                      <>
                        { renderPayByMollieLoading() }
                      </>
                    }

                    { showPayByCredits &&
                      <>
                        { !payWithCreditsResult && renderPayByCredits() }
                        { payWithCreditsResult && renderPaySubscriptionWithCreditsResult() }
                      </>
                    }

                    { payByBankTransfer &&
                      <>
                        { renderPayByBankTransfer() }
                      </>
                    }
                  </>
                }
              </>
              <Separator variant="h20" />
            </LoadingOverlay>
          </WrappingBusyMask>
          
        </Modal>
    )
    
}

const button_with_tooltip = css`
display: flex;
align-items: center;
`
