/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { Row, Col, Container, Card } from 'react-bootstrap'
import { size, map, get, head } from 'lodash'
import Loading from './Loading'
import { setModalProps, getModalProps } from '../actions/ui'
import { Modal } from 'react-bootstrap'
import { MobileOnly } from './layout/MobileOnly'
import { DesktopOnly } from './layout/DesktopOnly'
import { PhoneNumber } from './PhoneNumber'
import { confirmModal } from '../actions/ui'
import { CustomerNotificationCard, CustomerNotificationCardHeader } from './layout/CustomerNotificationCard'
import { BankTransferPaymentDetails } from './BankTransferPaymentDetails'
import { Timestamp } from './Timestamp'
import { Trans, Translation } from 'react-i18next'
import { CardHeader } from './layout/CardHeader'
import CardParagraphHeading from './layout/CardParagraphHeading'
import CardParagraphSubHeading from './layout/CardParagraphSubHeading'
import BusyMask from './BusyMask'
import CardInfoText from './layout/CardInfoText'
import { BlueButton } from './layout/BlueButton'
import { ButtonBar } from './layout/ButtonBar'
import { RedButton } from './layout/RedButton'
import { BlueLinkButton } from './layout/BlueLinkButton'
import { molliePaymentList } from '../actions/mollie_payment'
import { customerList } from '../actions/customer'
import { customerSubscriptionPayableList } from '../actions/customer_subscription'
import { Separator } from './layout/Separator'
import CurrencyValue from './CurrencyValue'
import FieldInfoText from './layout/FieldInfoText'
import MainLayout from './MainLayout'
import WrappingBusyMask from './WrappingBusyMask'
import { default_theme as theme } from '../emotion/theme'
import { handleSubmitResult } from '../actions/form'
import {showSuccess, showError} from '../actions/Error'
import { globalSettingsList } from '../actions/settings'

const MODAL_NAME = "PAY_SUBSCRIPTIONS_NOTIFICATION"

class PaySubscriptionsNotification extends Component {

    constructor(props) {
        super(props)
        this.state = {navigating_to_mollie: false}
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(customerSubscriptionPayableList.updatePaginationNumItemsPerPage(100))
        dispatch(customerSubscriptionPayableList.updateListFilter({payment_is_due: true}))
        dispatch(customerSubscriptionPayableList.fetchListIfNeeded())
        dispatch(customerList.ensureCustomerLoaded())
        dispatch(globalSettingsList.ensureGlobalSettingsLoaded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(customerSubscriptionPayableList.fetchListIfNeeded())
    }

    onHideModal = () => {
        const { dispatch } = this.props
        dispatch(setModalProps(MODAL_NAME, {show: false}))
    }
    
    onMakePayment = () => {
        const { history, dispatch, subscription, subscriptions } = this.props
        const that = this

        const on_ok = function(mollie_record) {
            if ( mollie_record.checkout_url ) {
                that.setState({navigating_to_mollie: true})
                molliePaymentList.rememberPendingMolliePaymentId(mollie_record.id)
                window.location = mollie_record.checkout_url
            }
        }

        if ( size(subscriptions) > 0 ) {
            history.push("/pay_subscriptions")
        } else {
            molliePaymentList.rememberAsReturnUrl()
            dispatch(molliePaymentList.startPaySubscription({subscription})).then((res) => handleSubmitResult({res, on_ok}))
        }
    }

    onCancel = () => {
        const { subscription, dispatch, onCancelled } = this.props
        
        const on_ok = (res) => {
            dispatch(customerSubscriptionPayableList.invalidateList())
            dispatch(customerSubscriptionPayableList.fetchListIfNeeded())
            showSuccess("Cancelled")
            if ( onCancelled ) {
                onCancelled()
            }
        }

        const onConfirmed = () => {
            dispatch(customerSubscriptionPayableList.cancelImmediately({subscription_id: subscription.id}))
                .then((res) => handleSubmitResult({res, on_ok}))
        }

        const heading = (
            <Trans>Cancel subscription</Trans>
        )

        const text = (
            <div>
              <b>
                <Trans i18nKey="pay_subscriptions_notification__cancel_confirmation"
                       components={[subscription.product_name]}
                       defaults="Cancel your unpaid <0></0> subscription."
                />
              </b>

              <br/>
              <b><Trans>Are you sure?</Trans></b>
              <br/>
            </div>
        )

        const confirm_text = "Yes, cancel this subscription"
        const cancel_text = "No, I will pay later"
        
        dispatch(confirmModal({text,
                               heading,
                               confirm_text,
                               cancel_text,
                               onConfirmed: onConfirmed}))

        
    }

    renderSubscriptionInfo({title, info, pricing, subscription}) {
        return (
            <div>
              <CustomerNotificationCardHeader>
                <div css={subscription_info_header_style}>
                  {title}
                </div>
              </CustomerNotificationCardHeader>

              { subscription.override_customer_payment_description &&
                <>
                  <Row>
                    <Col>
                      {info}
                    </Col>
                  </Row>
                  <Separator variant="h20" />
                  <Row>
                    <Col md={4}>
                      {pricing}
                    </Col>
                    <Col>
                      <div css={override_customer_payment_description_style}>
                        
                        {subscription.override_customer_payment_description}
                      </div>
                    </Col>
                  </Row>
                </>
              }
              
              { ! subscription.override_customer_payment_description &&
                <>
                  {info}
                  <Separator variant="h20" />
                  {pricing}
                </>
              }
              <Separator variant="h20" />
            </div>
        )
    }

    renderSubscription(subscription) {

        const { customer } = this.props
        
        if ( subscription.preferred_payment_mechanism == 'offline' ) {
            return this.renderOfflinePaymentSubscription(subscription)
        } else {
            return this.renderOnlinePaymentSubscription(subscription)
        }
    }
    
    renderOfflinePaymentSubscription(subscription) {
        const { customer, subscription_bank_transfer_details } = this.props

        const pricing = ( <>
          <div>
            <div css={info_row_style}>
              <Trans>Subscription</Trans>: <span css={bold_style}>{get(subscription, ["product_name"])}</span>
            </div>
            { subscription.phone_number_number && 
              <div css={info_row_style}>
                <Trans>Phone number</Trans>: <span css={bold_style}><PhoneNumber phone_number={subscription.phone_number_number} /></span>
              </div>
            }
            <div css={info_row_style}>
              <Trans>Amount due</Trans>: <span css={bold_style}><CurrencyValue value={subscription.amount_owing_including_vat_euros} use_span={true} /></span>
            </div>
          </div>

        </>
        )
        
        return (
            <CustomerNotificationCard is_active={true} override_style={offline_payment_style}>

              { subscription.is_active &&
                this.renderSubscriptionInfo({title: <>
                  <Trans>Attention</Trans>: &nbsp;
                    { subscription.override_customer_payment_title || <Trans>Your subscription payment is due</Trans> }
                </>,
                                             info: <>
                                               { subscription.expires_at && 
                                                 <Trans
                                                     i18nKey="paysubscriptions__this_subscription_will_expire"
                                                     components={[<Timestamp css={bold_style} value={subscription.expires_at} format="date_longmonth" use_span={true} />]}
                                                     defaults="Your current subscription will expire on <0></0>."
                                                 />
                                               }
                                             </>,
                                             pricing,
                                             subscription
                })
              }

              { subscription.is_waiting_for_first_activation &&
                this.renderSubscriptionInfo({title: <>
                  { subscription.override_customer_payment_title || <Trans>Your subscription requires activation</Trans> }
                </>,
                                             info: <>
                                               <Trans
                                                   i18nKey="paysubscriptions__your_subscription_will_be_activated_as_soon"
                                                   components={[<Timestamp css={bold_style} value={subscription.first_activation_expires_at} format="date_longmonth" use_span={true} />]}
                                                   defaults="Please note that the subscription will be automatically cancelled if you don't pay before <0></0>."
                                               />
                                             </>,
                                             pricing,
                                             subscription
                })
              }
              
              { ! subscription.is_active && subscription.can_reactivate &&
                this.renderSubscriptionInfo({title: <>
                  <Trans>Attention</Trans>:&nbsp;
                    { subscription.override_customer_payment_title || <Trans>Your subscription is deactivated</Trans>}
                </>,
                                             info: <>
                                               { ! subscription.auto_recurring_billing && 
                                                 <Trans
                                                     i18nKey="paysubscriptions__your_subscription_expired_on"
                                                     components={[<Timestamp value={subscription.expires_at} format="date_longmonth" use_span={true} />,
                                                                  <Timestamp value={subscription.reactivation_available_until} format="date_longmonth" use_span={true} />]}
                                                     defaults="Your subscription expired on <0></0>. You can reactivate the subscription (and keep the number) until <1></1>."
                                                 />
                                               }
                                               { subscription.auto_recurring_billing &&
                                                 <>
                                                   <Trans
                                                       i18nKey="paysubscriptions__your_recurring_subscription_expired_on"
                                                       components={[<Timestamp value={subscription.expires_at} format="date_longmonth" use_span={true} />]}
                                                       defaults="Your subscription expired on <0></0>. We were unable to process your automatic payment. Your phone number is now temporarilly deactivated."
                                                   />

                                                   <Separator variant="h10" />
                                                   <Trans
                                                       i18nKey="paysubscriptions__your_recurring_subscription_reactivation"
                                                       components={[<Timestamp value={subscription.reactivation_available_until} format="date_longmonth" use_span={true} />]}
                                                       defaults="Please use the Pay Now button to restart automatic payments. You have until <0></0> to do so. After this the subscription will be cancelled."
                                                   />
                                                 </>
                                               }
                                               
                                               <Separator variant="h20" />
                                             </>,
                                             pricing,
                                             subscription
                })
              }

              { subscription.expires_at && 
                <Trans
                    i18nKey="paysubscriptionsmethodchoicepopup__existing_sub_pay_before"
                    components={[<Timestamp value={subscription.expires_at} format="date_longmonth" use_span={true} />]}
                    defaults="Pay before <0></0> using the information below. Please make sure to use the correct payment reference."
                />
              }
              { ! subscription.expires_at &&
                <>
                <Trans
                    i18nKey="paysubscriptionsmethodchoicepopup__new_sub_pay_before"
                    defaults="Pay using the information below. Please make sure to use the correct payment reference."
                />
                &nbsp;
                </>
              }
              <br/>
              
              <Trans>Once your payment is received, your subscription will be updated and the invoice will be made available.</Trans>

              <Separator variant="h10" />
              <BankTransferPaymentDetails customer={customer} />
              <Separator variant="h30" />
              
              <ButtonBar>
                <BlueButton onClick={() => this.onMakePayment()}>
                  <Trans>Change payment method</Trans>
                </BlueButton>
                <RedButton onClick={() => this.onCancel()}>
                  <Trans>Cancel</Trans>
                </RedButton>
              </ButtonBar>

            </CustomerNotificationCard>

            
        )
    }

    renderOnlinePaymentSubscription(subscription) {

        const { customer } = this.props
        const pricing = ( <>
          <div>
            <div css={info_row_style}>
              <Trans>Subscription</Trans>: <span css={bold_style}>{get(subscription, ["product_name"])}</span>
            </div>
            { subscription.phone_number_number && 
              <div css={info_row_style}>
                <Trans>Phone number</Trans>: <span css={bold_style}><PhoneNumber phone_number={subscription.phone_number_number} /></span>
              </div>
            }
            <div css={info_row_style}>
              <Trans>Amount due</Trans>: <span css={bold_style}><CurrencyValue value={subscription.amount_owing_including_vat_euros} use_span={true} /></span>
            </div>
          </div>

          <Separator variant="h30" />
          
          <ButtonBar>
            <BlueButton onClick={() => this.onMakePayment()}>
              <Trans>Pay now</Trans>
            </BlueButton>
            <RedButton onClick={() => this.onCancel()}>
              <Trans>Cancel</Trans>
            </RedButton>
          </ButtonBar>
        </>
        )

        
        return (
            <CustomerNotificationCard is_active={subscription.is_active}>
              
              { subscription.is_active &&
                this.renderSubscriptionInfo({title: <>
                  <Trans>Attention</Trans>: &nbsp;
                    { subscription.override_customer_payment_title || <Trans>Your subscription payment is due</Trans> }
                </>,
                                             info: <>
                                               { subscription.expires_at && 
                                                 <Trans
                                                     i18nKey="paysubscriptions__this_subscription_will_expire"
                                                     components={[<Timestamp css={bold_style} value={subscription.expires_at} format="date_longmonth" use_span={true} />]}
                                                     defaults="Your current subscription will expire on <0></0>."
                                                 />
                                               }
                                             </>,
                                             pricing,
                                             subscription
                })
              }

              { subscription.is_waiting_for_first_activation &&
                this.renderSubscriptionInfo({title: <>
                  { subscription.override_customer_payment_title || <Trans>Your subscription requires activation</Trans> }
                </>,
                                             info: <>
                                               <Trans
                                                   i18nKey="paysubscriptions__your_subscription_will_be_activated_as_soon"
                                                   components={[<Timestamp css={bold_style} value={subscription.first_activation_expires_at} format="date_longmonth" use_span={true} />]}
                                                   defaults="Please note that the subscription will be automatically cancelled if you don't pay before <0></0>."
                                               />
                                             </>,
                                             pricing,
                                             subscription
                })
              }
              
              { ! subscription.is_active && subscription.can_reactivate &&
                this.renderSubscriptionInfo({title: <>
                  <Trans>Attention</Trans>:&nbsp;
                    { subscription.override_customer_payment_title || <Trans>Your subscription is deactivated</Trans>}
                </>,
                                             info: <>
                                               { ! subscription.auto_recurring_billing && 
                                                 <Trans
                                                     i18nKey="paysubscriptions__your_subscription_expired_on"
                                                     components={[<Timestamp value={subscription.expires_at} format="date_longmonth" use_span={true} />,
                                                                  <Timestamp value={subscription.reactivation_available_until} format="date_longmonth" use_span={true} />]}
                                                     defaults="Your subscription expired on <0></0>. You can reactivate the subscription (and keep the number) until <1></1>."
                                                 />
                                               }
                                               { subscription.auto_recurring_billing &&
                                                 <>
                                                   <Trans
                                                       i18nKey="paysubscriptions__your_recurring_subscription_expired_on"
                                                       components={[<Timestamp value={subscription.expires_at} format="date_longmonth" use_span={true} />]}
                                                       defaults="Your subscription expired on <0></0>. We were unable to process your automatic payment. Your phone number is now temporarilly deactivated."
                                                   />

                                                   <Separator variant="h10" />
                                                   <Trans
                                                       i18nKey="paysubscriptions__your_recurring_subscription_reactivation"
                                                       components={[<Timestamp value={subscription.reactivation_available_until} format="date_longmonth" use_span={true} />]}
                                                       defaults="Please use the Pay Now button to restart automatic payments. You have until <0></0> to do so. After this the subscription will be cancelled."
                                                   />
                                                 </>
                                               }
                                               
                                               <Separator variant="h20" />
                                             </>,
                                             pricing,
                                             subscription
                })
              }
              
            </CustomerNotificationCard>
        )
    }

    renderModal(children) {
        return (
            <Modal show={true}
                   size="xl"
                   centered
                   aria-labelledby="contained-modal-title-vcenter"
                   onHide={this.onHideModal} >

              <Modal.Header closeButton>
              </Modal.Header>

              <Modal.Body>
                {children}
              </Modal.Body>
              
            </Modal>
        )
    }
    
    render() {
        const { is_ready, is_saving, subscription, subscriptions, is_loading, history, isModalVisible } = this.props
        const { navigating_to_mollie } = this.state
        return (
            <WrappingBusyMask is_loading={is_saving || is_loading || !is_ready || navigating_to_mollie}>
              { subscription &&
                <>
                  <MobileOnly>
                    { isModalVisible && this.renderModal(this.renderSubscription(subscription)) }
                  </MobileOnly>
                  <DesktopOnly>
                    {this.renderSubscription(subscription)}
                    <Separator variant="h40" />
                  </DesktopOnly>
                </>
              }
            </WrappingBusyMask>
        )
    }
    
}

function mapStateToProps(state, props) {
    const { initialSubscriptionId, onCancelled } = props
    const subscriptions = customerSubscriptionPayableList.getVisibleObjects()
    const subscription = initialSubscriptionId ? customerSubscriptionPayableList.getObject(initialSubscriptionId) : head(subscriptions)
    const customer = customerList.getCustomer()
    const subscription_bank_transfer_details = globalSettingsList.getSetting('subscription_bank_transfer_details')
    const isModalVisible = get(getModalProps(state, MODAL_NAME), "show", true) !== false
    return {
        subscription,
        subscriptions,
        is_ready: customerSubscriptionPayableList.isReady(),
        is_loading: customerSubscriptionPayableList.isLoading(),
        is_saving: molliePaymentList.getIsSavingObject() || customerSubscriptionPayableList.getIsSavingObject(),
        customer,
        isModalVisible,
        onCancelled,
        subscription_bank_transfer_details
        
    }
}

export default withRouter(connect(mapStateToProps)(PaySubscriptionsNotification))

const bold_style = css`
font-weight: 500;
`

const info_row_style = css`
margin-bottom: 5px;
`

const override_customer_payment_description_style = css`
white-space: pre-wrap;
`

const subscription_info_header_style = css`
font-weight: 500;
`

const offline_payment_style = css`
background-color: ${theme.colors.gray3};
border: solid 1px ${theme.colors.gray1};
`
