/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import FieldInfoText from '../../components/layout/FieldInfoText'
import { jsx, css } from '@emotion/react'
import CurrencyValue from '../../components/CurrencyValue'
import { Formik, Form, FieldArray, Field } from 'formik'
import { Modal } from 'react-bootstrap'
import { default_theme as theme } from '../../emotion/theme'
import { head, get, map, keys, filter, size } from 'lodash'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { FormikInputField } from '../../components/form/InputField'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'
import { Separator } from '../../components/layout/Separator'
import { InlineIcon } from '../../components/layout/InlineIcon'
import { BlueLinkButton } from '../../components/layout/BlueLinkButton'
import { BlueButton } from '../../components/layout/BlueButton'
import { GrayButton } from '../../components/layout/GrayButton'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { countryList } from '../../actions/country'
import { adminNumberTypes } from '../actions/admin_dropdown_options'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    'country': Yup.string().required("Required"),
    'number_type': Yup.string().required("Required"),
    'price_per_year_excluding_vat_euros': Yup.string().required("Required"),
    'price_per_month_excluding_vat_euros': Yup.string().required("Required")
})

export const AdminProductSupplementNumberTypes = ({formik_props, field_name, RIGHT_WIDTH, LEFT_WIDTH}) => {

    const dispatch = useDispatch()
    const country_options = useSelector(() => countryList.getAsOptionsWithPriority())
    const countries_by_id = useSelector(() => countryList.getObjectsById())
    const number_type_options = useSelector(() => adminNumberTypes.getAsOptions())
    const number_types_by_id = useSelector(() => adminNumberTypes.getObjectsById())
    const [isAddingSupplement, setIsAddingSupplement] = useState(false)

    useEffect(() => {
        dispatch(countryList.updatePaginationNumItemsPerPage(1000))
        dispatch(countryList.fetchListIfNeeded())
        dispatch(adminNumberTypes.fetchListIfNeeded())
    }, [])

    const onAddASupplement = (values, arrayHelpers) => {
        const index = size(get(formik_props, ["values", field_name]))
        arrayHelpers.insert(index, values)
        onStopAddingSupplement()
    }

    const onDeleteSupplement = (index, arrayHelpers) => {
        arrayHelpers.remove(index)
    }

    const onStartAddingSupplement = () => {
        setIsAddingSupplement(true)
    }

    const onStopAddingSupplement = () => {
        setIsAddingSupplement(false)
    }

    const renderAddingSupplementModal = (arrayHelpers) => {
        return (
            <Modal show={true}
                   onHide={onStopAddingSupplement} >

              <Modal.Header closeButton>
                <Modal.Title>
                  Sync Trigger
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>

                <Formik initialValues={{country: 'nl',
                                        number_type: 'geographic'}}
                        onSubmit={(values) => onAddASupplement(values, arrayHelpers)}
                        enableReinitialize={true}
                        validationSchema={validationSchema}
                >
                  {supplement_formik_props => {
                      const { values } = supplement_formik_props
                      return (
                          <Form>
                            <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                            <Row>
                              <Col>
                                Country
                                <FormikDropdownField name="country"
                                                     formik_props={supplement_formik_props}
                                                     options={country_options}
                                                     placeholder="Country" />

                              </Col>
                            </Row>

                            <Separator variant="h20" />

                            <Row>
                              <Col>
                                Number type
                                <FormikDropdownField name="number_type"
                                                     formik_props={supplement_formik_props}
                                                     options={number_type_options}
                                                     placeholder="Number type" />
                                
                              </Col>
                            </Row>

                            <Separator variant="h20" />

                            <Row>
                              <Col>
                                Supplement price per year (ex VAT, Euros)
                                <FormikInputField name="price_per_year_excluding_vat_euros"
                                                  formik_props={supplement_formik_props}
                                                  placeholder="Price per year (euros)" />
                                
                              </Col>
                            </Row>
                            <Separator variant="h20" />
                            <Row>
                              <Col>
                                Supplement price per month (ex VAT, Euros)
                                <FormikInputField name="price_per_month_excluding_vat_euros"
                                                  formik_props={supplement_formik_props}
                                                  placeholder="Price per month (euros)" />
                                
                              </Col>
                            </Row>

                            <Separator variant="h40" />

                            <Separator with_top_border={true} variant="h10" />

                            <ButtonBar align_right={true}>
                              <GrayButton onClick={onStopAddingSupplement}>Cancel</GrayButton>
                              <BlueButton type="submit">Submit</BlueButton>
                            </ButtonBar>
                            
                          </Form>
                      )
                  }}
                </Formik>
              </Modal.Body>
            </Modal>
        )
    }
    
    return (
        <>
          <Row>
            <Col md={LEFT_WIDTH} css={section_name_style}>
              Number type supplements
            </Col>
            <Col md={RIGHT_WIDTH}>
              <FieldInfoText>
                If the customer chooses a subscription phone number which matches any of the criteria below, they will be charged an additional subscription cost.
              </FieldInfoText>
              <Separator variant="h10" />
              
              <FieldArray name={field_name}
                          render={arrayHelpers => (
                              <>
                                {map(get(formik_props.values, field_name), (supplement, index) => {
                                    return (
                                        <Row>
                                          <Col md={3}>
                                            {get(countries_by_id, [supplement.country, "name"])}
                                          </Col>
                                          <Col md={3}>
                                            {get(number_types_by_id, [supplement.number_type, "name"])}
                                          </Col>
                                          <Col md={2}>
                                            <CurrencyValue enable_inline_spacing={true} value={supplement.price_per_year_excluding_vat_euros} /> per year
                                          </Col>
                                          <Col md={2}>
                                            <CurrencyValue enable_inline_spacing={true} value={supplement.price_per_month_excluding_vat_euros} /> per month
                                          </Col>
                                          <Col md={1}>
                                            <InlineIcon icon_name="delete" onClick={(evt) => onDeleteSupplement(index, arrayHelpers)} />
                                          </Col>
                                          <Separator variant="h10" />
                                        </Row>
                                    )
                                })}

                                { isAddingSupplement && renderAddingSupplementModal(arrayHelpers) }
                                <div>
                                  <Separator variant="h10" />
                                  <BlueLinkButton auto_disable={false} onClick={onStartAddingSupplement}>
                                    <InlineIcon icon_name="add_no_circle" variant="blue" />
                                    Add a number type supplement
                                  </BlueLinkButton>
                                </div>
                                
                                <Separator variant="h50" />
                              </>
                          )} 
              />
            </Col>
          </Row>
        </>
    )
}

const section_name_style = css`
font-weight: 500;
font-size: 18px;
`

    

