/** @jsxImportSource @emotion/react */
import React, { Component } from 'react'
import { Trans, Translation } from 'react-i18next'
import { jsx, css } from '@emotion/react'
import { useField } from 'formik'
import { map, size } from 'lodash'
import { default_theme as theme } from '../../emotion/theme'
import FormError from './FormError'
import FormWarning from './FormWarning'
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import { RadioGroupFieldStandalone } from './RadioGroupFieldStandalone'
import { Separator } from '../layout/Separator'
import radio_selected from '../../images/radio_selected.svg'
import radio_unselected from '../../images/radio_unselected.svg'

export const TraditionalRadioGroupField = ({ label, options, renderLabel, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const { formik_props } = props
    const [field, meta] = useField(props)
    const className = (meta && meta.touched && meta.error && error_class) || null
    props.options = null

    const onSelected = (value) => {
        formik_props.setFieldValue(field.name, value)
    }

    const value = field.value

    return (
        <div css={className}>
          { map(options, function(option, index) {
              const is_selected = ""+option.value===""+value
              return (
                  <div key={index}>
                    <div css={[option_style,
                               (is_selected && selected_option_style) || unselected_option_style]}
                         onClick={(evt) => onSelected(option.value)}>
                      <img src={is_selected ? radio_selected : radio_unselected} />
                      <Separator variant="w20" />
                      <Trans>{renderLabel ? renderLabel({value: option.value, label: option.label}) : option.label}</Trans>
                    </div>
                    { index < size(options)-1 && 
                      <Separator variant="h10" />
                    }
                  </div>
              )
          })}
          <FormError meta={meta} />
          <FormWarning meta={meta} />
        </div>
    )
}

const error_class = css`
  border: 1px solid ${theme.colors.error};
`

const option_style = css`
height: 30px;
border: 0px;
font-size: 14px;
color: #000;
display:flex;
align-items: center;
padding-right: 16px;
margin-left: 2px;
margin-right: 2px;

&:focus, &:hover, &:active, &:focus:active {
text-decoration: none;
}

`

const selected_option_style = css`
`

const unselected_option_style = css`
cursor: pointer;
`
