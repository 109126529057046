/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { jsx, css } from '@emotion/react'
import { default_theme as theme } from '../../emotion/theme'
import { filter, head, get, map, keys, split, size } from 'lodash'
import WrappingBusyMask from '../../components/WrappingBusyMask'
import { AdminQuickLink } from './AdminQuickLink'
import { FormikDropdownField } from '../../components/form/Dropdown'
import { FormikDatePicker } from '../../components/form/DatetimePicker'
import { TraditionalRadioGroupField } from '../../components/form/TraditionalRadioGroupField'
import AdminCreditInformationCard from './AdminCreditInformationCard'
import { adminTransactionList } from '../actions/admin_transaction'
import { adminOfflineSubscriptionTransactionList } from '../actions/admin_offline_subscription_transaction'
import { adminCustomerSubscriptionOwing } from '../actions/admin_customer_subscription'
import { ButtonBar } from '../../components/layout/ButtonBar'
import { adminCustomerList } from '../actions/admin_customer'
import { BlueButton } from '../../components/layout/BlueButton'
import { adminTransactionTypes } from '../actions/admin_dropdown_options'
import { CardHeader } from '../../components/layout/CardHeader'
import { LanguageFlag } from '../../components/LanguageFlag'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import { Separator } from '../../components/layout/Separator'
import FieldInfoText from '../../components/layout/FieldInfoText'
import CurrencyValue from '../../components/CurrencyValue'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from '../../components/Loading'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikInputField } from '../../components/form/InputField'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {showSuccess, showError} from '../../actions/Error'
import Button from 'react-bootstrap/Button'
import CardInfoText from '../../components/layout/CardInfoText'
import { handleSubmitResult } from '../../actions/form'
import { FormikGeneralFormErrors } from '../../components/form/GeneralFormErrors'

const adhocTransactionValidationSchema = Yup.object().shape({
    'amount_excluding_vat_euros': Yup.number().required("Required"),
    'description': Yup.string().required("Required"),
    'transaction_type': Yup.string().required("Required")
})

const subscriptionOfflinePaymentTransactionValidationSchema = Yup.object().shape({
    'subscription_id': Yup.string().required("Required"),
})



export const AdminCustomerTransaction = ({}) => {

    const dispatch = useDispatch()
    const history = useHistory()

    const { customer_id } = useParams()
    const customer = useSelector(() => adminCustomerList.getObject(customer_id))
    
    const subscriptions_owing = useSelector(() => adminCustomerSubscriptionOwing.getVisibleObjects())
    const subscriptions_owing_options = map(subscriptions_owing, function(sub) {

        const phone_number = get(sub, ["customer_phone_number", "phone_number", "number"], "No phone number set")
        
        return {value: sub.id,
                label: `${sub.short_ref} - ${sub.product_name} - ${phone_number} - ${sub.status_name} - €${sub.amount_owing_including_vat_euros} (incVAT)`}
    })
    const is_loading_subscriptions_owing = useSelector(() => adminCustomerSubscriptionOwing.isLoading())
    
    const transaction_type_options = useSelector(() => adminTransactionTypes.getAsOptions())
    const is_loading = useSelector(() => adminTransactionList.isLoading())
    const is_busy = useSelector(() => adminTransactionList.getIsSavingObject() || adminOfflineSubscriptionTransactionList.getIsSavingObject())
    const initial_adhoc_values = {description: "",
                                  transaction_type: "",
                                  amount_excluding_vat_euros: ""}
    
    const initial_subscription_offline_payment_values = {line_item_description: "",
                                                         subscription_id: ""}

    
    useEffect(() => {
        dispatch(adminCustomerList.ensureObjectLoaded(customer_id))
        dispatch(adminTransactionTypes.fetchListIfNeeded())
        dispatch(adminCustomerSubscriptionOwing.updatePaginationNumItemsPerPage(100))
        dispatch(adminCustomerSubscriptionOwing.updateListFilter({customer_id: customer_id,
                                                                  payment_is_due: true}))
        dispatch(adminCustomerSubscriptionOwing.fetchListIfNeeded())
    }, [customer_id])
    
    const onCreateAdhocTransaction = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminCustomerList.invalidateObject(customer_id))
            showSuccess("Saved", "Transaction created")
            history.push(`/admin/customer/${customer_id}/transactions`)
        }

        values = Object.assign({}, values, {customer: customer_id})
        dispatch(adminTransactionList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    const onCreateSubscriptionOfflinePaymentTransaction = (values, formik_funcs) => {
        const on_ok = function(json) {
            dispatch(adminCustomerList.invalidateObject(customer_id))
            showSuccess("Saved", "Transaction created")
            history.push(`/admin/customer/${customer_id}/transactions`)
        }

        values = Object.assign({}, values, {customer: customer_id})
        dispatch(adminOfflineSubscriptionTransactionList.saveNewObject(values)).then((res) => handleSubmitResult({res, formik_funcs, on_ok}))
    }

    const renderAdhocTransactionForm = () => {
        return (
            <>
                <CardHeader title={`Add adhoc transaction for ${get(customer, ["user", "display_name"], '')}`} />
                <Separator variant="h20" />
                
                <Formik
                    initialValues={initial_adhoc_values}
                    onSubmit={onCreateAdhocTransaction}
                    enableReinitialize={true}
                    validationSchema={adhocTransactionValidationSchema}
                >
                  {formik_props => {
                      const { values } = formik_props
                      return (
                          <Form>
                            <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                            <Row>
                              <Col>
                                <BootstrapForm.Group>
                                  <BootstrapForm.Label>Euro amount, inc VAT. (use a negative amount to decrease the customer credit balance)</BootstrapForm.Label>
                                  <FormikInputField name="amount_excluding_vat_euros" placeholder="Amount (Euros, ex VAT)" />
                                </BootstrapForm.Group>

                                { get(values, "amount_excluding_vat_euros", 0) > 0 &&
                                  <BootstrapForm.Group>
                                    <BootstrapForm.Label>Tick if this transaction should generate an invoice</BootstrapForm.Label>
                                    <FieldInfoText>
                                      If True, VAT will be logged against the transaction if applicable for this customer
                                    </FieldInfoText>
                                    <FormikCheckboxField name="create_invoice" />
                                  </BootstrapForm.Group>
                                }
                                
                                <BootstrapForm.Group>
                                  <BootstrapForm.Label>Reason</BootstrapForm.Label>
                                  <FormikDropdownField formik_props={formik_props} 
                                                       type="text" 
                                                       name="transaction_type" 
                                                       options={transaction_type_options} />
                                </BootstrapForm.Group>
                                
                                <BootstrapForm.Group>
                                  <BootstrapForm.Label>Description</BootstrapForm.Label>
                                  <FormikTextarea rows="2" name="description" placeholder="Description" />
                                </BootstrapForm.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <ButtonBar>
                                  <BlueButton type="submit">
                                    Save
                                  </BlueButton>
                                </ButtonBar>
                              </Col>
                            </Row>
                          </Form>
                  )}
                  }
                </Formik>
            </>
        )
    }

    const renderSubscriptionOption = ({value, label}) => {
        const subscriptionId = value
        const subscription = head(filter(subscriptions_owing, (sub) => sub.id == subscriptionId))
        return (
            <AdminQuickLink name="customer_subscription" customer={customer.id} value={subscriptionId} label={label} />
        )
    }

    const renderSubscriptionOfflinePaymentTransactionForm = () => {
        return (
            <>
              <CardHeader title={`Add offline subscription payment for ${get(customer, ["user", "display_name"], '')}`} />

              <FieldInfoText>
                This section can be used to process a bank transfer payment or other non-xoip payment.
                <br/>
                Only subscriptions for which a payment is due are supported, and the full amount must be paid.
                <br/>
                Using this form will trigger all normal subscription payment and activation processes, similar to if the customer had paid via Mollie.
                <br/>
                (If you want to process a partial payment, use the regular transaction form above)
              </FieldInfoText>
              
              <Separator variant="h20" />
              <WrappingBusyMask is_loading={is_loading_subscriptions_owing || is_busy}>


                { size(subscriptions_owing_options) == 0 &&
                  <i>No subscriptions owing for this customer</i>
                }
                
                { size(subscriptions_owing_options) > 0 &&
                  
                  <Formik
                      initialValues={initial_subscription_offline_payment_values}
                      onSubmit={onCreateSubscriptionOfflinePaymentTransaction}
                      enableReinitialize={true}
                      validationSchema={subscriptionOfflinePaymentTransactionValidationSchema}
                  >
                    {formik_props => {
                        const { values } = formik_props

                        const selectedSubscriptionId = get(values, "subscription_id")
                        const selectedSubscription = head(filter(subscriptions_owing, (sub) => sub.id == selectedSubscriptionId))
                        const hasBeenPreviouslyActivated = selectedSubscription && Boolean(selectedSubscription.originally_activated_at)
                        
                        return (
                            <Form>
                              <FormikGeneralFormErrors render={(msg) => <Row><Col>{msg}</Col></Row>} />
                              <Row>
                                <Col>
                                  <BootstrapForm.Group>
                                    <BootstrapForm.Label>Subscriptions Owing</BootstrapForm.Label>
                                    <TraditionalRadioGroupField
                                        formik_props={formik_props}
                                        name="subscription_id"
                                        options={subscriptions_owing_options}
                                        renderLabel={renderSubscriptionOption}
                                    />
                                  </BootstrapForm.Group>

                                  <Separator variant="h40" />

                                  { Boolean(selectedSubscription) &&
                                    <>
                                      
                                      <BootstrapForm.Group>
                                        <BootstrapForm.Label>Invoice line item description</BootstrapForm.Label>
                                        <FieldInfoText>
                                          If not empty, this description will appear below the normal line item in the invoice.
                                          The normal line item will look similar to this: 
                                        </FieldInfoText>
                                        <FormikTextarea rows="2" name="line_item_description" placeholder="Optional line item description" />
                                      </BootstrapForm.Group>
                                      <Separator variant="h30" />
                                      
                                      { ! hasBeenPreviouslyActivated &&
                                        <>
                                          <BootstrapForm.Group>
                                            <BootstrapForm.Label>Tick to send the normal welcome email to the customer for new subscription activation</BootstrapForm.Label>
                                            <FieldInfoText>
                                              customer__new_subscription_welcome_email
                                            </FieldInfoText>
                                            <FormikCheckboxField name="send_activation_email_to_customer" />
                                          </BootstrapForm.Group>
                                          <Separator variant="h30" />
                                        </>
                                      }

                                      <BootstrapForm.Group>
                                        <BootstrapForm.Label>Tick to send a payment confirmation email to the customer, with invoice attached.</BootstrapForm.Label>
                                        <FieldInfoText>
                                          customer__on_offline_subscription_payment_received
                                        </FieldInfoText>
                                        <FormikCheckboxField name="send_offline_payment_confirmed_email_to_customer" />
                                      </BootstrapForm.Group>
                                    </>
                                  }
                                  
                                </Col>
                              </Row>
                              { Boolean(selectedSubscription) &&
                                <Row>
                                  <Col>
                                    <Separator variant="h30" />
                                    <ButtonBar>
                                      <BlueButton type="submit">
                                        Save
                                      </BlueButton>
                                    </ButtonBar>
                                  </Col>
                                </Row>
                              }
                            </Form>
                    )}
                    }
                  </Formik>
                }
              </WrappingBusyMask>
            </>
        )
    }
    
    return (
        <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                       {name: 'customers', label: 'Customers', url: '/admin/customers'},
                                       {name: 'customer', label: `${get(customer, ["user", "display_name"], '')} ${get(customer, ["short_id"])}`, url: `/admin/customer/${customer_id}`},
                                       {name: 'new_transaction', label: 'New transaction', url: `/admin/customer/${customer_id}/create_transaction`}]}>
          <Container fluid>

            <Row>
              <Col md="8">
                { renderAdhocTransactionForm() }

                <Separator variant="h40" />
                <hr/>
                { renderSubscriptionOfflinePaymentTransactionForm() }
              </Col>
              <Col md="4">
                <AdminCreditInformationCard customer_id={customer_id} />
              </Col>
            </Row>
          </Container>
        </AdminMainLayout>
    )
}


const breadcrumb_item = css`
display: flex;
align-items: center;
`
